@@ -0,0 +1,144 @@
<template>
  <v-dialog
    :value="showCookiesManager"
    max-width="600"
    class="manageDialog"
    persistent
  >
    <v-card>
      <div class="manage-title px-5 py-3">
        {{ $t("COOKIE_CONSENT.SETTINGS") }}
      </div>
      <div class="px-5 py-3">
        <p class="subtitle">{{ $t("COOKIE_CONSENT.PRIVACY") }}</p>
        <p class="text">
          {{ $t("COOKIE_CONSENT.TEXT1") }}
        </p>
        <p class="text">
          {{ $t("COOKIE_CONSENT.TEXT2") }}
        </p>
        <p class="text">
          {{ $t("COOKIE_CONSENT.TEXT3") }}
          <a href="/privacy" class="my-link" target="_blank" rel="noreferrer">{{
            $t("COOKIE_CONSENT.LINK")
          }}</a
          >.
        </p>
        <p class="subtitle">{{ $t("COOKIE_CONSENT.MANAGE_COOKIES") }}</p>
        <v-switch
          v-for="cookieType in types"
          :key="cookieType.value"
          v-model="acceptedCookies"
          :label="cookieType.label"
          :value="cookieType.value"
          color="#ff5d03"
          class="switch"
          dense
          hide-details
          :disabled="cookieType.value === 'mandatory'"
        ></v-switch>
        <div class="buttons mt-7">
          <button class="btn accept mb-3 pa-3" @click="$emit('acceptCookies')">
            {{ $t("COOKIE_CONSENT.ACCEPT") }}
          </button>
          <button
            class="btn save pa-3"
            @click="$emit('acceptCookies', acceptedCookies)"
          >
            {{ $t("COOKIE_CONSENT.SAVE_SETTINGS") }}
          </button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    types: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      acceptedCookies: []
    };
  },
  computed: {
    ...mapState(["cookiesConsent", "showCookiesManager"])
  },
  created() {
    if (this.cookiesConsent && this.cookiesConsent.types) {
      this.acceptedCookies = [...this.cookiesConsent.types];
    } else {
      this.acceptedCookies = [];
    }
  },
  methods: {
    acceptCookies(all = false) {
      if (all) {
        this.acceptedCookies = [...this.types.map(t => t.value)];
      }

      this.$emit("acceptCookies", this.acceptedCookies);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  background-color: var(--primary) !important;
  color: white;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  letter-spacing: 1px !important;
  &:hover {
    color: white;
    opacity: 0.7;
  }
}
.agree {
  background-color: var(--primary);
}

.save {
  background-color: rgb(161, 159, 159) !important;
}

.manageDialog {
  z-index: 2000;
  color: white;
}

.manage-title {
  color: white;
  background-color: var(--primary);
  font-size: 16px;
  padding: 10px 20px;
}

.subtitle {
  font-weight: 600;
}

.text {
  font-size: 14px;
}

.switch {
  text-transform: capitalize;
}

.my-link {
  color: var(--primary);
  text-decoration: none;
}
</style>
