var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-details-wrapper"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(_vm.object.name))]),_c('p',[_vm._v(_vm._s(_vm.object.description))]),_c('ul',[_c('price',{attrs:{"object":_vm.object,"location":_vm.cfg.LOCATION_EU}}),_c('availability',{attrs:{"availability-info":_vm.availabilityInfo}}),_c('li',[_c('div',{staticClass:"btns"},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"number","min":"1","max":_vm.max,"disabled":_vm.disabled || _vm.disableButtonQuantity},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}}),(!_vm.disableButtonQuantity)?_c('b-button',{staticClass:"button",attrs:{"variant":"primary","disabled":_vm.disableButton ||
                _vm.disableButtonInner ||
                !_vm.cookiesConsent.accepted ||
                _vm.disableButtonQuantity},on:{"click":_vm.addToCart}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-cart-arrow-down")]),_vm._v(" "+_vm._s(_vm.cookiesConsent.accepted ? _vm.$t("addToCart") : _vm.$t("noCookieConsent"))+" ")],1):_c('b-button',{staticClass:"button",on:{"click":function($event){$event.stopPropagation();_vm.showEnquiry = true}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-cart-arrow-down")]),_vm._v(" "+_vm._s(_vm.$t("sendEnquiry"))+" ")],1)],1)],1)])],1),_c('v-snackbar',{attrs:{"top":"","left":"","timeout":_vm.timeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(_vm._s(_vm.$t("COMMON.CLOSE")))])],1),_c('enquiry',{attrs:{"dialog":_vm.showEnquiry,"part":_vm.object.name,"manufacturer":_vm.object.manufacturer},on:{"snack":() => {
        _vm.snackbarText = _vm.$t('DETAILS.ENQUIRY_SENT');
        _vm.snackbar = true;
      },"modal":val => {
        _vm.showEnquiry = val;
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }